/* stylelint-disable stylelint-plugins/no-hardcoded-colors */
/* stylelint-disable max-nesting-depth */

@import '../core/helpers';

@mixin spx-card($theme, $status) {
  .spx-card {
    mat-card-title {
      margin-bottom: 0 !important;
      padding: 0 1rem;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .spx-icon svg {
        fill: #fff;
        stroke: #fff;
      }
    }

    mat-card-actions {
      margin: 0 !important;
      padding: 16px 16px 16px 20px !important;
    }

    .mat-mdc-card-header-text{
      margin: 0 !important;
      width: 100%;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .spx-card__status {
      &.primary {
        background: linear-gradient(90deg, color($theme) 60%, #fff);
      }

      &.accent {
        background: linear-gradient(90deg, color($theme, 'accent') 60%, #fff);
      }

      &.warn {
        background: linear-gradient(90deg, color($theme, 'warn') 60%, #fff);
      }

      // style for each status in the status map
      @each $statusKey in $status {
        &.status-#{$statusKey} {
          background: linear-gradient(90deg, color($theme, 'status', $statusKey) 60%, #fff);
        }
      }
    }
  }
}
/* stylelint-enable stylelint-plugins/no-hardcoded-colors */
/* stylelint-enable max-nesting-depth */
